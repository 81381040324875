<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        :class="{
        'login-signin-on': this.state === 'signin',
        'login-signup-on': this.state === 'signup',
        'login-forgot-on': this.state === 'forgot'
      }"
        id="kt_login"
    >
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-lg-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <div class="mb-10">
              <inline-svg src="media/svg/auth-logo.svg"/>
            </div>
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder font-size-h4 font-size-h1-lg pb-2"
                >
                  {{ $t("AUTH.LOGIN.TITLE") }}
                </h3>
                <span class="font-weight-bold font-size-h6">{{ $t("AUTH.LOGIN.DESC") }}</span>
<!--                <h5>-->
<!--                  <a id="kt_login_signup"-->
<!--                     class="text-primary font-weight-bolder"-->
<!--                     @click="showForm('signup')"-->
<!--                  >Создать аккаунт</a>-->
<!--                </h5>-->
              </div>
              <template v-if="isLocalLogin">
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder">{{ $t("AUTH.INPUT.EMAIL_LABEL") }}</label>
                  <div
                      id="example-input-group-1"
                      label=""
                      label-for="example-input-1"
                  >
                    <input
                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="email"
                        name="email"
                        ref="email"
                        :placeholder="$t('AUTH.INPUT.EMAIL_PLACEHOLDER')"
                        v-model="form.email"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label class="font-size-h6 font-weight-bolder pt-5"
                    >{{ $t("AUTH.INPUT.PASSWORD_LABEL") }}</label
                    >
                  </div>
                  <div
                      id="example-input-group-2"
                      label=""
                      label-for="example-input-2"
                  >
                    <input
                        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                        type="password"
                        name="password"
                        ref="password"
                        :placeholder="$t('AUTH.INPUT.PASSWORD_PLACEHOLDER')"
                        v-model="form.password"
                        autocomplete="off"
                    />
                  </div>
                </div>
                <div class="pb-lg-0">
                  <label class="checkbox text-primary font-size-h6 text-hover-primary float-left">
                    <input type="checkbox" checked="checked" v-model="rememberMe" @change="check($event)" name="Checkboxes1"/>
                    <span class="mr-2"></span>
                    {{ $t("AUTH.LOGIN.REMEMBER_ME") }}
                  </label>
                  <!--СКРЫТО ДО РЕАЛИЗАЦИИ-->
                  <!--                <a role="button" class="text-primary font-size-h6 text-hover-primary float-right"-->
                  <!--                   id="kt_login_forgot"-->
                  <!--                   @click="showForm('forgot')"-->
                  <!--                >Забыли пароль?</a>-->
                  <!--СКРЫТО ДО РЕАЛИЗАЦИИ-->
                </div>
                <div class="pb-lg-0 pt-10">
                  <button ref="kt_login_signin_submit"
                          :disabled="isLoginStart"
                          class="btn btn-primary font-weight-bolder btn-block font-size-h6 px-15 py-4 my-3 mr-3"
                  >{{ $t("AUTH.LOGIN.BUTTON") }}</button>
                </div>
              </template>
              <div v-else class="pb-lg-0">
                <button type="button"
                        :disabled="isLoginStart"
                        @click="loginFromMicrosoft"
                        ref="kt_login_signin_microsoft_submit"
                        class="btn btn-outline-primary btn-text-danger btn-hover-text-white font-weight-bolder btn-block px-8 py-8 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/microsoft-icon.svg"/>
                  </span
                  >{{ isLoginMicrosoftStart ? $t("AUTH.LOGIN.LOGIN_MICROSOFT_STARTED_BUTTON") : $t("AUTH.LOGIN.LOGIN_MICROSOFT_BUTTON") }}
                </button>
              </div>
              <a class="btn btn-link-primary font-size-lg font-weight-bolder mt-10"
                @click.prevent="isLocalLogin = !isLocalLogin">{{ !isLocalLogin ? $t("AUTH.LOGIN.LOCAL_AUTH") : $t("AUTH.LOGIN.MICROSOFT_AUTH") }}</a>
              <p class="text-muted font-size-h6" style="position: absolute; bottom: 0;">
                Deskki &copy; {{ $moment().year() }}{{ $t("FOOTER.SYSTEM_PATH_2") }}
              </p>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder font-size-h4 font-size-h1-lg"
                >
                  Sign Up
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p>
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="text"
                    placeholder="Fullname"
                    name="fullname"
                    ref="fullname"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    ref="remail"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref="rpassword"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="password"
                    placeholder="Confirm password"
                    name="cpassword"
                    ref="cpassword"
                    autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                    ref="kt_login_signup_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    style="width:150px;"
                >
                  Submit
                </button>
                <button
                    type="button"
                    id="kt_login_signup_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
                class="form"
                novalidate="novalidate"
                id="kt_login_forgot_form"
                ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder font-size-h4 font-size-h1-lg"
                >
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    type="email"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                    type="button"
                    id="kt_login_forgot_submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div class="login-aside d-none d-lg-flex flex-column flex-row-auto bgi-position-x-center bgi-position-y-center p-5"
           :style="{ backgroundImage: `url(${backgroundImage})`, backgroundColor: '#5A628A'}"

      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <h3
              class="font-weight-bolder text-center font-size-h4 font-size-h1-lg pb-5"
              style="color: #FFFFFF;"
          >
            {{ $t("AUTH.GENERAL.TITLE") }}
          </h3>
          <span class="font-weight-bold text-center font-size-h6"
                style="color: #FFFFFF;"
          >
            {{ $t("AUTH.GENERAL.DESC") }}
          </span>
        </div>
      </div>
      <!--end::Aside-->
    </div>
  </div>
</template>

<script>
import "@/assets/sass/pages/login/login-1.scss";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, MICROSOFT_LOGIN_LINK, MICROSOFT_LOGIN, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      rememberMe: false,
      isLocalLogin: false,
      isLoginStart: false,
      isLoginMicrosoftStart: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
          process.env.BASE_URL + "media/svg/auth-image.svg"
      );
    }
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Не введён email пользователя."
            },
            emailAddress: {
              // message: 'The value is not a valid email address'
              message: 'Не введён валидный email пользователя.'
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Не введён пароль пользователя."
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required"
            }
          }
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required"
            },
            identical: {
              compare: function() {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same"
            }
          }
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      let email = this.form.email;
      let password = this.form.password;

      this.$store.dispatch(LOGOUT);
      this.isLoginStart = true;

      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store.dispatch(LOGIN, { email, password })
            .then(() => {
              window.localStorage.setItem("local_login", 'true');
              this.$router.push({ name: "my-office" })
            })
            .catch(() => {});

        submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );

        this.isLoginStart = false;
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      // Swal.fire({
      //   title: "",
      //   text: "Please, provide correct data!",
      //   icon: "error",
      //   confirmButtonClass: "btn btn-secondary",
      //   heightAuto: false
      // });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const password = this.$refs.rpassword.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password
            })
            .then(() => this.$router.push({ name: "my-office" }));

        submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
        );
      }, 2000);
    });

    this.fv1.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  created() {
    window.localStorage.getItem("remember_me") === 'true' ? this.rememberMe = true : this.rememberMe = false;
    if (this.$route.query.code) {
      this.isLoginMicrosoftStart = true;
      this.isLoginStart = true;
      this.$store
          .dispatch(MICROSOFT_LOGIN, this.$route.query)
          .then(() => {
            this.$router.push({ name: "my-office" })
          });
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      let form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
      );
    },
    check() {
      window.localStorage.setItem("remember_me", this.rememberMe);
    },
    loginFromMicrosoft() {
      this.$store.dispatch(LOGOUT);

      const submitButton = this.$refs["kt_login_signin_microsoft_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.isLoginMicrosoftStart = true;
      this.isLoginStart = true;
      this.$store
          .dispatch(MICROSOFT_LOGIN_LINK)
          .then((value) => {
            window.location = value.url;
          })
          .catch(() => {});
    }
  }
};
</script>
